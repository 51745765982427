import * as React from "react"
// import React, { useState } from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"

import { colourStrToRGB } from "../components/colour"
import { relativeLum } from "../components/colour"
import { RGBtoColourStr } from "../components/colour"
import { sRGBtoHSV } from "../components/colour"
import { HSVtosRGB } from "../components/colour"
import { contrastRatio } from "../components/colour"

import Contrast from "../components/contrast"



const Colours = () => (

  <Layout>
    <Seo title="Colours" />
    <h1>Using colour functions <span role="img" aria-label="unicorn emoji">🦄</span></h1>
    <h2>Make this page something special :)</h2>
    <p>Examples of using the colour functions from the ..components/colour (originally used in our Colour Contrast Checker)</p>
    <p>Rel lunmance = {relativeLum('#00FFee')}</p>
    <p>{RGBtoColourStr(colourStrToRGB('#ff6633'))}</p>
    <p>Hue = {sRGBtoHSV('#00ff7e').hue}</p>
    <p>Rgb.green from HSV = {HSVtosRGB(sRGBtoHSV('#00ff7e')).green}</p>
    <p>Contrast #ff99ee #99ee34 = {contrastRatio('#ff99ee', '#99ee34')}</p>
    <p>Look into ways for working with these to make a better non-codepen colour checker :)</p>
    <a href="https://humaan.co.uk/static/gramophone.usdz">Open the AR kit gramaphone model</a>
    <Contrast></Contrast>
  </Layout>
)

export default Colours
