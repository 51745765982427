import * as React from "react"
import {contrastRatio} from "../components/colour"




const Contrast = () => {

  const [fieldValue, setFieldValue] = React.useState('');

  const showInput = (e) => {
    setFieldValue(e.target.value);
    //console.log(e.target.value);
    //alert("Those colours have a contrast of " + e.target.value);
  }

  const checkContrast = (e) => {
    //get the value from to field
    e.preventDefault();
    console.log({fieldValue});
    //have we got two colours?
    let pColours = fieldValue.split(',');
    if (pColours.length>1){
      //continue
      let first = pColours[0];
      let second = pColours[1];

      let contrastR = contrastRatio(first,second);
      //console.log ('contrast ratio is '+contrastR);
      alert('contrast ratio is '+ contrastR);
      
      //alert(contrastR);

      //check what we've got colour wise


    //check them all against each other

    //retunr a value

    }else{
      alert("Not enough colours passed in - I was expecting a comma ,");
      //console.log("Not enough colours passed in - I was expecting a comma ,");
    }

    
  }



return(
  <div>
    
  <label htmlFor="name">Colour</label>
  <input className="border-2 border-blue-500 rounded-md" name="firstName" id="name" onChange={showInput} />
  <button className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded-md " onClick={checkContrast}>Check Contrast</button>
  
</div>
)
  
}

// Contrast.propTypes = {
//   val: PropTypes.array
// }
// Contrast.defaultProps = {
//   val: []
// }
export default Contrast;