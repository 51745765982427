/**
 * 
 * Colour functions taken from - //
 * https://github.com/squizlabs/HTML_CodeSniffer/blob/90b8660fbc22698f98f3d50122241c123b3491c0/Standards/WCAG2AAA/Sniffs/Principle1/Guideline1_4/1_4_3_Contrast.js#L151
 *
 * 
 * 
/**
   * Calculate relative luminescence for a colour in the sRGB colour profile.
   *
   * Supports rgb() and hex colours. rgba() also supported but the alpha
   * channel is currently ignored.
   * Hex colours can have an optional "#" at the front, which is stripped.
   * Relative luminescence formula is defined in the definitions of WCAG 2.0.
   * It can be either three or six hex digits, as per CSS conventions.
   * It should return a value in the range [0.0, 1.0].
   *
   * @param {String} colour The colour to calculate from.
   *
   * @returns {Number}
   */

 export const relativeLum = (colour)  => {
    if (colour.charAt) {
      colour = colourStrToRGB(colour);
    }

    var transformed = {};
    for (var x in colour) {
      if (colour[x] <= 0.03928) {
        transformed[x] = colour[x] / 12.92;
      } else {
        transformed[x] = Math.pow((colour[x] + 0.055) / 1.055, 2.4);
      }
    } //end for

    var lum =
      transformed.red * 0.2126 +
      transformed.green * 0.7152 +
      transformed.blue * 0.0722;
    return lum;
  };

  /**
   * Convert a colour string to a structure with red/green/blue elements.
   *
   * Supports rgb() and hex colours (3 or 6 hex digits, optional "#").
   * rgba() also supported but the alpha channel is currently ignored.
   * Each red/green/blue element is in the range [0.0, 1.0].
   *
   * @param {String} colour The colour to convert.
   *
   * @returns {Object}
   */
   export const colourStrToRGB = (colour) => {
     colour = colour.toLowerCase();

    if (colour.substring(0, 3) === "rgb") {
      // rgb[a](0, 0, 0[, 0]) format.
      var matches = /^rgba?\s*\((\d+),\s*(\d+),\s*(\d+)([^)]*)\)$/.exec(colour);
      colour = {
        red: matches[1] / 255,
        green: matches[2] / 255,
        blue: matches[3] / 255
      };
    } else {
      // Hex digit format.
      if (colour.charAt(0) === "#") {
        colour = colour.substr(1);
      }

      if (colour.length === 3) {
        colour = colour.replace(/^(.)(.)(.)$/, "$1$1$2$2$3$3");
      }

      colour = {
        red: parseInt(colour.substr(0, 2), 16) / 255,
        green: parseInt(colour.substr(2, 2), 16) / 255,
        blue: parseInt(colour.substr(4, 2), 16) / 255
      };
    }

    return colour;
  }

  // colourStrToRGB(colour){
  //   return colour;
  // }
  /**
   * Convert an RGB colour structure to a hex colour.
   *
   * The red/green/blue colour elements should be on a [0.0, 1.0] scale.
   * Colours that can be converted into a three Hex-digit string will be
   * converted as such (eg. rgb(34,34,34) => #222). Others will be converted
   * to a six-digit string (eg. rgb(48,48,48) => #303030).
   *
   * @param {Object} colour Structure with "red", "green" and "blue" elements.
   *
   * @returns {String}
   */
   export const RGBtoColourStr = (colour) => {
    let colourStr = "#";
    colour.red = Math.round(colour.red * 255);
    colour.green = Math.round(colour.green * 255);
    colour.blue = Math.round(colour.blue * 255);

    if (
      colour.red % 17 === 0 &&
      colour.green % 17 === 0 &&
      colour.blue % 17 === 0
    ) {
      // Reducible to three hex digits.
      colourStr += (colour.red / 17).toString(16);
      colourStr += (colour.green / 17).toString(16);
      colourStr += (colour.blue / 17).toString(16);
    } else {
      if (colour.red < 16) {
        colourStr += "0";
      }
      colourStr += colour.red.toString(16);

      if (colour.green < 16) {
        colourStr += "0";
      }
      colourStr += colour.green.toString(16);

      if (colour.blue < 16) {
        colourStr += "0";
      }
      colourStr += colour.blue.toString(16);
    }

    return colourStr;
  }

    /**
   * Convert an RGB colour into hue-saturation-value.
   *
   * This is used for calculations changing the colour (for colour contrast
   * purposes) to ensure that the hue is maintained.
   * The parameter accepts either a string (hex or rgb() format) or a
   * red/green/blue structure.
   * The returned structure has hue, saturation, and value components: the
   * latter two are in the range [0.0, 1.0]; hue is in degrees,
   * range [0.0, 360.0).
   * If there is no saturation then hue is technically undefined.
   *
   * @param {String|Object} colour A colour to convert.
   *
   * @returns {Object}
   */
    export const sRGBtoHSV = (colour) => {
        // If this is a string, then convert to a colour structure.
        if (colour.charAt) {
          colour = colourStrToRGB(colour);
        }
    
        var hsvColour = {
          hue: 0,
          saturation: 0,
          value: 0
        };
    
        var maxColour = Math.max(colour.red, colour.green, colour.blue);
        var minColour = Math.min(colour.red, colour.green, colour.blue);
        var chroma = maxColour - minColour;
    
        if (chroma === 0) {
          hsvColour.value = colour.red;
        } else {
          hsvColour.value = maxColour;
          if (maxColour === colour.red) {
            hsvColour.hue = (colour.green - colour.blue) / chroma;
          } else if (maxColour === colour.green) {
            hsvColour.hue = 2.0 + (colour.blue - colour.red) / chroma;
          } else {
            hsvColour.hue = 4.0 + (colour.red - colour.green) / chroma;
          } //end if
    
          hsvColour.hue = hsvColour.hue * 60.0;
          //console.log('hsv = '+hsvColour.hue);
          if (hsvColour.hue >= 360.0) {
            hsvColour.hue -= 360.0;
          }
          //update for HSV colours less than 0
          if (hsvColour.hue < 0) {
            hsvColour.hue = 360.0 - (hsvColour.hue*-1); 
          }
          
          //console.log('hsv = '+hsvColour.hue);
    
          hsvColour.saturation = chroma / hsvColour.value;
        } //end if
    
        return hsvColour;
      }

      /**
   * Convert a hue-saturation-value structure into an RGB structure.
   *
   * The hue element should be a degree value in the region of [0.0, 360.0).
   * The saturation and value elements should be in the range [0.0, 1.0].
   * Use RGBtoColourStr to convert back into a hex colour.
   *
   * @param {Object} hsvColour A HSV structure to convert.
   *
   * @returns {Object}
   */
  export const HSVtosRGB = (hsvColour) => {
    let colour = {
      red: 0,
      green: 0,
      blue: 0
    };

    if (hsvColour.saturation === 0) {
      colour.red = hsvColour.value;
      colour.green = hsvColour.value;
      colour.blue = hsvColour.value;
    } else {
      var chroma = hsvColour.value * hsvColour.saturation;
      var minColour = hsvColour.value - chroma;
      var interHue = hsvColour.hue / 60.0;
      var interHueMod = interHue - 2 * Math.floor(interHue / 2);
      var interCol = chroma * (1 - Math.abs(interHueMod - 1));

      switch (Math.floor(interHue)) {
        case 0:
          colour.red = chroma;
          colour.green = interCol;
          break;

        case 1:
          colour.green = chroma;
          colour.red = interCol;
          break;

        case 2:
          colour.green = chroma;
          colour.blue = interCol;
          break;

        case 3:
          colour.blue = chroma;
          colour.green = interCol;
          break;

        case 4:
          colour.blue = chroma;
          colour.red = interCol;
          break;

        case 5:
          colour.red = chroma;
          colour.blue = interCol;
          break;

        default:;
      } //end switch

      colour.red = colour.red + minColour;
      colour.green = colour.green + minColour;
      colour.blue = colour.blue + minColour;
    } //end if

    return colour;
  }
  /**
   * Calculate the contrast ratio between two colours.
   *
   * Colours should be in rgb() or 3/6-digit hex format; order does not matter
   * (ie. it doesn't matter which is the lighter and which is the darker).
   * Values should be in the range [1.0, 21.0]... a ratio of 1.0 means "they're
   * exactly the same contrast", 21.0 means it's white-on-black or v.v.
   * Formula as per WCAG 2.0 definitions.
   *
   * @param {String} colour1 The first colour to compare.
   * @param {String} colour2 The second colour to compare.
   *
   * @returns {Number}
   */
  export const contrastRatio = (colour1, colour2) => {
    var ratio =
      (0.05 + relativeLum(colour1)) / (0.05 + relativeLum(colour2));
    if (ratio < 1) {
      ratio = 1 / ratio;
    }

    return ratio;
  }

